import { LocationProvider } from "@/components/locations/context";
import "@phase2/ochsner-yext-application/dist/style.css";
import { Template, TemplateRenderProps } from "@yext/pages";
import MainSearch from "../components/mainSearch";
import { getConfig } from "../configs/globalSearch";
import Layout from "../design/components/layout";
import { SearchProvider } from "../design/providers/searchProvider";
import "../index.css";

const MainSearchPage: Template<TemplateRenderProps> = () => {
    const { experienceKey, theme, enviroment } = getConfig().config

    return (
        <SearchProvider
            experienceKey={experienceKey}
            headlessId="main-site-search"
            initialSearchEnvironment={enviroment}
        >
            <LocationProvider>
                <Layout theme={theme}>
                    <MainSearch verticalToIgnore={['promotion_banner']} />
                </Layout>
            </LocationProvider>
        </SearchProvider>
    )
}

export default MainSearchPage;
